import React, { useRef, useState } from "react";
import {
  Flex,
  Table,
  Button,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import { TablePagination } from "@trendmicro/react-paginations";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { initialFilter } from "utils/constant";
import SeasonTableRow from "./components/Row";
import Loading from "components/Layout/Loading";
import { Select } from "chakra-react-select";
import DatePicker from "components/DatePicker/DatePicker";

const HistoryMission = () => {
  const refSearchButton = useRef(null);
  const params = useParams();
  const history = useHistory();
  const { id } = params || {};
  const [filter, setFilter] = useState({
    ...initialFilter,
    member: id,
  });
  const [searchValue, setSearchValue] = useState("");
  const [seasonDetail, setSeasonDetail] = useState(null);
  const [sort, setSort] = useState(null);
  const [type, setType] = useState(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const [{ data, loading, error }, refetch] = useAxios({
    url: `${ROOT_API}${API_ROUTES.HISTORY_MISSION}`,
    params: filter,
  });

  const onChangeDate = (type) => (date) => {
    setSort((prev) => ({
      ...prev,
      ...(type === "start" && { end: null }),
      [type]: date,
    }));
  };

  const handelClickRow = (seasonDetail, modalType) => {
    setSeasonDetail(seasonDetail);
  };
  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX="auto" pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Flex alignItems="center">
              <ArrowBackIcon
                cursor="pointer"
                boxSize={6}
                onClick={() => {
                  history.goBack();
                }}
              />
              <Text ml={2} fontSize="xl" color={textColor} fontWeight="bold">
                History Mission
              </Text>
            </Flex>
          </CardHeader>
          <CardBody pb={4}>
            <Flex gap={3} mb={3} flexDirection={"column"} alignItems={"start"}>
              <Flex gap={3} w={"full"}>
                <InputGroup>
                  <InputRightElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputRightElement>
                  <Input
                    type="text"
                    variant="outline"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    onKeyUp={(event) => {
                      if (event.key === "Enter" && event?.target?.value) {
                        const searchBtn = refSearchButton?.current;
                        if (searchBtn !== null) {
                          searchBtn.click();
                        }
                      }
                    }}
                  />
                </InputGroup>
                <Flex w="700px">
                  <FormControl>
                    <Select
                      placeholder="Lọc theo"
                      isClearable
                      value={type}
                      menuShouldBlockScroll
                      onChange={(e) => {
                        setType(e);
                      }}
                      options={[
                        {
                          label: "Nhiệm vụ xem live",
                          value: "COUNTDOWN",
                        },
                        {
                          label: "Nhiệm vụ hàng ngày",
                          value: "DAILY",
                        },
                        {
                          label: "Nhiệm vụ đăng nhập hàng ngày",
                          value: "ROLL_CALL",
                        },
                        {
                          label: "Điểm thưởng NV đăng nhập hằng ngày",
                          value: "ROLL_CALL_BONUS",
                        },
                        {
                          label: "Điểm thưởng NV Hằng ngày",
                          value: "DAILY_BONUS",
                        },
                        {
                          label: "Điểm thưởng NV chia sẻ link live",
                          value: "SHARE",
                          dailyType: "SHARE",
                        },
                        {
                          label: "Điểm thưởng NV chia sẻ link mời",
                          value: "INVITE_MEMBER",
                          dailyType: "INVITE_MEMBER",
                        },
                        {
                          label: "Điểm thưởng NV tham gia phòng chat",
                          value: "JOIN_CHAT_ROOM",
                          dailyType: "JOIN_CHAT_ROOM",
                        },
                        {
                          label: "Điểm thưởng NV xem live nhận định",
                          value: "VIEW_LIVE_DISCUSS",
                          dailyType: "VIEW_LIVE_DISCUSS",
                        },
                      ]}
                    ></Select>
                  </FormControl>
                </Flex>
              </Flex>
              <Flex alignItems={"end"} gap={3}>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "300px" }}
                  maxW="300px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    Start Date
                  </FormLabel>
                  <DatePicker
                    selectedDate={sort?.start}
                    onChange={(date) => onChangeDate("start")(date)}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "300px" }}
                  maxW="300px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    End Date
                  </FormLabel>
                  <DatePicker
                    selectedDate={sort?.end}
                    minDate={sort?.start}
                    onChange={(date) => onChangeDate("end")(date)}
                  />
                </FormControl>
                <Button
                  ref={refSearchButton}
                  disabled={!searchValue && !type && !sort}
                  colorScheme="blue"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      ...sort,
                      type: type?.dailyType ? "DAILY" : type?.value,
                      searchKeyword: searchValue,
                      pageIndex: 0,
                      dailyType: type?.dailyType,
                    });
                  }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    setSearchValue("");
                    setType(null);
                    setSort(null);
                    setFilter({
                      ...filter,
                      searchKeyword: "",
                      pageIndex: 0,
                      start: null,
                      end: null,
                      type: null,
                    });
                  }}
                >
                  Reset
                </Button>
              </Flex>
            </Flex>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th borderColor={borderColor} color="gray.400">
                        Username
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Loại
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Số Phút Xem Live
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Lượt mời bạn bè
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Lượt chia sẻ
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Số lần thực hiện
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Ngày trong tuần
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Điểm
                      </Th>
                      <Th
                        borderColor={borderColor}
                        color="gray.400"
                        isTruncated
                      >
                        Trạng thái thu thập
                      </Th>
                      <Th
                        borderColor={borderColor}
                        color="gray.400"
                        isTruncated
                      >
                        Trạng thái hoàn thành
                      </Th>
                      <Th
                        borderColor={borderColor}
                        color="gray.400"
                        isTruncated
                      >
                        Ngày thực hiện
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.data?.length
                      ? data?.data?.map((row, index, arr) => {
                          return (
                            <SeasonTableRow
                              row={row}
                              isLast={index === arr.length - 1 ? true : false}
                              key={index}
                              refetchData={refetch}
                              handelClickRow={handelClickRow}
                            />
                          );
                        })
                      : ""}
                  </Tbody>
                </Table>
                <Flex justifyContent={"flex-end"}>
                  <TablePagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default HistoryMission;
